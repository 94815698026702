import React, {Suspense} from 'react'
import Container from '@packages/justo-parts/lib/components/Container'
import useNavbarV2 from '@page-components/Layout/Website/Layouts/Default/NavbarV2/hooks/useNavbarV2'
import CartV3 from '@page-components/Order/CartV3'
import Loading from '@page-components/Order/Products/ProductsContent/Loading'

export default function LayoutV2({children}: {children: React.ReactNode}) {
  const isNavbarV2Active = useNavbarV2()
  return (
    <>
      {!isNavbarV2Active ? <CartV3.AsSidebar /> : null}
      <Container>
        <Suspense fallback={<Loading fullScreen />}>{children}</Suspense>
      </Container>
    </>
  )
}
